<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>الدول</h4>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = true" color="primary">اضافة</v-btn>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="countries"
        :headers="headers"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn @click="edit_country(item)" small icon>
            <v-icon small>edit</v-icon>
          </v-btn>

          <v-btn @click="get_delete_id(item.id)" small icon>
            <v-icon small>delete</v-icon>
          </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog persistent max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>
          {{ dialog_title }}
        </v-card-title>
        <!--/ title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-tabs class="mb-3" v-model="tabs" background-color="gray">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab v-model="tabs" v-for="(lang, i) in langs" :key="i">
                {{ lang.lang_name }}
              </v-tab>
            </v-tabs>
            <!--/tabs -->
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(lang, i) in langs" :key="i">
                <v-text-field
                  v-model="country.country_name[lang.locale]"
                  :error-messages="tabs == 0 ? name_err : ''"
                  @focus="name_err = ''"
                >
                  <template slot="label">
                    <strong class="red--text" v-if="tabs == 0">*</strong>
                    اسم الدولة
                  </template>
                </v-text-field>
                <!--/  country_name -->
              </v-tab-item>
            </v-tabs-items>
          </v-form>
          <!--/ form -->
        </v-card-text>
        <!--/text -->
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            :disabled="save_loader"
            :loading="save_loader"
            color="primary"
            small
            @click="save()"
            >حفظ
          </v-btn>
          <v-btn
            :disabled="save_loader"
            @click="close_dialog()"
            color="error"
            text
            small
          >
            الغاء</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ Dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف الدولة</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف الدولة ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_country()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->
  </v-container>
  <!--/ container -->
</template>

<script>
export default {
  name: "countries",

  data() {
    return {
      tabs: 0,
      country: {
        country_name: {},
        id: null,
      },
      name_err: "",
      country_id: null,
      langs: [],
      dialog: false,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      countries: [],
      save_loader: false,
      headers: [
        {
          value: "default_name",
          text: "اسم الدولة",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
    };
  },

  watch: {},
  computed: {
    dialog_title() {
      return this.country.id != null ? "تعديل الدولة" : "اضافة دولة";
    },
    def_lang() {
      let def_lang = this.langs.find((e) => e.is_default);
      return def_lang;
    },
  },
  methods: {
    get_langs() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;

          this.langs = Object.assign([], res.data.data);
        })
    },
    get_countries() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "countries",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.countries = Object.assign([], res.data.data);
        })
    },
    get_delete_id(id) {
      this.delete_id = id;
      this.delete_dialog = true;
    },

    delete_country() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `countries/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.get_countries();
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          })
      }
    },

    edit_country(item) {
      this.country = Object.assign(item);
      this.dialog = true;
    },
    save() {
      if (this.country.country_name[this.def_lang.locale]) {
        this.name_err = "";
        this.save_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: this.country.id  ? `countries/${this.country.id }` : 'countries',
              method: this.country.id ? "put" : "post",
            },
            data: this.country,
          })
          .then((res) => {
            this.save_loader = false;
            this.close_dialog();
            this.get_countries();
          })
      } else {
        this.tabs = 0;
        this.name_err = "حقل مطلوب";
      }
    },
    close_dialog() {
      this.dialog = false;
      this.country = {
        country_name: {},
        id: null,
      };
      this.name_err = "";
      this.tabs = 0;
    },
  },
  mounted() {
    this.get_langs();
    this.get_countries();
  },
};
</script>
